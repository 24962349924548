import { useGlobalModalContext } from "@components/GlobalModalProvider";
import Modal from "@components/ui/modals/Modal";
import ModalContent from "@components/ui/modals/ModalContent";
import { CommonCMS, commonReplacements } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { ModalTypes } from "@lib/enums/ModalTypes";
import { useApi } from "@lib/helpers/useApi";
import { getEmailValidationSchema } from "@lib/validators/user/logInSchema";
import { H } from "@ui/components/content/heading/A11yHeading";
import { Text } from "@ui/components/content/text/Text";
import { Button } from "@ui/components/forms/button/Button";
import { Field } from "@ui/components/forms/field/Field";
import { FormControl } from "@ui/components/forms/form-control/FormControl";
import { FormErrorMessage } from "@ui/components/forms/form-control/FormError";
import { FormLabel } from "@ui/components/forms/form-control/FormLabel";
import { Input } from "@ui/components/forms/input/Input";
import { Box } from "@ui/components/layout/box/Box";
import { ModalBody, ModalFooter } from "@ui/components/overlay/modal/Modal";
import { Form, Formik } from "formik";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useState } from "react";

const ForgotPassword: FC = () => {
    const { getMultiple } = useMicrocopy();
    const { locale, defaultLocale } = useRouter();
    const { showModal, hideModal } = useGlobalModalContext();
    const [emailAddress, setEmailAddress] = useState<string>();
    const [emailSent, setEmailSent] = useState<boolean>(false);

    function handleClose() {
        hideModal();
    }

    const microcopies = (() => {
        return getMultiple(
            CommonCMS.shared,
            [
                CommonCMS.email,
                CommonCMS.password,
                CommonCMS.error.email,
                CommonCMS.error.requiredFieldError,
                CommonCMS.resetPassword,
                CommonCMS.resetPasswordSentText,
                CommonCMS.resetEmailSentThanks,
                CommonCMS.sendResetPasswordButton,
                CommonCMS.backToLogin,
                CommonCMS.error.general.title,
                CommonCMS.resetPassDesc,
                CommonCMS.resetEmailSentSuccess,
                CommonCMS.error.maxCharacters,
            ],
            { replacements: commonReplacements.maxEmailCharacters }
        );
    })();

    const headers = {
        title: microcopies[CommonCMS.resetPassword],
        email: microcopies[CommonCMS.email],
        password: microcopies[CommonCMS.password],
        resetPasswordSentText: microcopies[CommonCMS.resetPasswordSentText],
        sendReset: microcopies[CommonCMS.sendResetPasswordButton],
        backToLogin: microcopies[CommonCMS.backToLogin],
        resetPassDesc: microcopies[CommonCMS.resetPassDesc],
        resetEmailSentSuccess: microcopies[CommonCMS.resetEmailSentSuccess],
        resetEmailSentThanks: microcopies[CommonCMS.resetEmailSentThanks],
        generalError: microcopies[CommonCMS.error.general.title],
        errors: {
            email: microcopies[CommonCMS.error.email],
            requiredField: microcopies[CommonCMS.error.requiredFieldError],
            maxCharacters: microcopies[CommonCMS.error.maxCharacters],
        },
    };

    const sendEmailMutation = useApi.useMutate("/api/auth/send-reset-link", {
        method: "POST",
        onError: (error) => {
            return error;
        },
    });

    const handleEmailSubmission = async ({ localEmailAddress }) => {
        try {
            await sendEmailMutation.mutateAsync({
                email: localEmailAddress,
                locale,
                defaultLocale,
            });
        } catch (error) {
            return error;
        }
    };

    const headerBeforeSend = (
        <H size="h3" color="black" fontSize="desktopBodyTextNormal">
            {headers?.title.toUpperCase()}
        </H>
    );
    const headerAfterSend = (
        <H size="h3" color="black" fontSize="desktopBodyTextNormal">
            {headers?.resetPasswordSentText?.toUpperCase()}
        </H>
    );
    if (emailSent) {
        return (
            <Modal closeOnOverlayClick={false} onClose={handleClose}>
                <ModalContent header={headerAfterSend}>
                    <ModalBody>
                        <Box data-testid="forgotPassword_emailSent-messaging">
                            <Text mb={6} fontWeight={"semibold"}>
                                {headers?.resetEmailSentSuccess}
                            </Text>
                            {sendEmailMutation.isError && (
                                <Text color="red.200">{headers.generalError}</Text>
                            )}
                        </Box>
                        <Box>
                            <Button
                                w={"100%"}
                                type={"submit"}
                                form={"logInForm"}
                                isLoading={sendEmailMutation.status === "pending"}
                                onClick={() =>
                                    handleEmailSubmission({ localEmailAddress: emailAddress })
                                }
                                data-testid="forgotPassword_sendReset-button"
                            >
                                <span>{headers.sendReset}</span>
                            </Button>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            fontWeight={"normal"}
                            data-testid="forgotPassword_backtoLogin-button"
                            variant="link"
                            onClick={() => showModal(ModalTypes.login)}
                        >
                            <span>{headers.backToLogin}</span>
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    }

    return (
        <Modal closeOnOverlayClick={false} onClose={handleClose}>
            <Formik
                validationSchema={getEmailValidationSchema(headers.errors)}
                initialValues={{
                    email: "",
                }}
                onSubmit={async (values) => {
                    const localEmailAddress = values.email;
                    await handleEmailSubmission({ localEmailAddress });
                    setEmailAddress(localEmailAddress);
                    setEmailSent(true);
                }}
            >
                {({ submitForm, errors, isSubmitting, touched }) => {
                    return (
                        <Form noValidate={true} id="resetPasswordForm">
                            <ModalContent header={headerBeforeSend}>
                                <ModalBody>
                                    <Box
                                        mb={6}
                                        data-privacy="true"
                                        data-testid="forgotPassword_sendReset-messaging"
                                    >
                                        {headers.resetPassDesc && (
                                            <Text color="black" mb="2">
                                                {headers.resetPassDesc}
                                            </Text>
                                        )}
                                        {sendEmailMutation.isError && (
                                            <Text color="red.200">{headers.generalError}</Text>
                                        )}
                                        <FormControl
                                            isRequired
                                            isInvalid={(!!errors.email && touched.email) as boolean}
                                        >
                                            <FormLabel
                                                htmlFor="email"
                                                fontSize={[
                                                    "mobileBodyTextSmall",
                                                    "desktopBodyTextSmall",
                                                ]}
                                            >
                                                {headers.email}
                                            </FormLabel>
                                            <Field
                                                as={Input}
                                                id="email"
                                                name="email"
                                                type="email"
                                                sx={{
                                                    width: "115%",
                                                }}
                                            />
                                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <Button
                                            w={"100%"}
                                            type={"submit"}
                                            form={"logInForm"}
                                            isLoading={isSubmitting}
                                            onClick={submitForm}
                                            data-testid="forgotPassword_sendReset-button"
                                        >
                                            <span>{headers.sendReset}</span>
                                        </Button>
                                    </Box>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        variant="link"
                                        fontWeight={"normal"}
                                        data-testid="forgotPassword_backtoLogin-button"
                                        onClick={() => showModal(ModalTypes.login)}
                                    >
                                        <span>{headers.backToLogin}</span>
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default ForgotPassword;
