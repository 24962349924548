import type { IMicrocopy, IMicrocopySet } from "@contentful-api/types/contentful";
import { Logger, LogTag, ServiceType } from "@lib/monitoring/logger";
import type { Replacements } from "@lib/utils/interpolateText";
import { interpolateText } from "@lib/utils/interpolateText";
import { get as _get, isEmpty } from "lodash-es";
import { createContext, useContext } from "react";

export type MicrocopySetKey = "shared" | "page" | string;

export type MicrocopyGetOptions = {
    replacements?: Replacements;
    fromMultiple?: boolean;
};

export interface MicrocopyContextState {
    shared: IMicrocopySet;
    page: IMicrocopySet;
    [key: string]: IMicrocopySet;
}

export interface MicrocopyContextAPI {
    get: (
        microcopySetKey: MicrocopySetKey,
        microcopyKey: string,
        options?: MicrocopyGetOptions
    ) => string | null;
    getMultiple: (
        microcopySetKey: MicrocopySetKey,
        microcopyKeys: string[],
        options?: MicrocopyGetOptions
    ) => { [key: string]: string } | null;
}

enum MicrocopyType {
    GET_SINGLE = "getSingle",
    GET_MULTIPLE = "getMultiple",
}

enum MicrocopyError {
    MISSING_MICROCOPY_SET = "Missing Microcopy Set",
    MISSING_MICROCOPY = "Missing Microcopy",
}

const MicrocopyContext = createContext<MicrocopyContextState | undefined>(undefined);
const { Provider: MicrocopyProvider } = MicrocopyContext;

const useMicrocopy = (): MicrocopyContextAPI => {
    const context = useContext(MicrocopyContext);

    if (context === undefined) {
        throw new Error("useMicrocopy must be within MicrocopyProvider");
    }
    const MCLogger = (key: string, set: string, mcType: MicrocopyType, type: MicrocopyError) => {
        const temporaryWindow: any = typeof window !== "undefined" ? window : {};
        const pathName = temporaryWindow.location?.pathname;

        Logger.warn(ServiceType.CONTENTFUL, `Miss Microcopy ${key} in ${set}`, {
            tag: LogTag.MICROCOPY,
            key: key,
            set: set,
            pathName: pathName,
            type: mcType,
            error: {
                name: type,
            },
        });
    };
    const get = (
        microcopySetKey: MicrocopySetKey,
        microcopyKey: string,
        options?: MicrocopyGetOptions
    ): string | null => {
        const microcopySet: IMicrocopySet = _get(context, microcopySetKey);
        const mcType = options?.fromMultiple
            ? MicrocopyType.GET_MULTIPLE
            : MicrocopyType.GET_SINGLE;

        if (!microcopySet || !microcopySet?.fields?.microcopy) {
            MCLogger(microcopyKey, microcopySetKey, mcType, MicrocopyError.MISSING_MICROCOPY_SET);
            return null;
        }

        const result = microcopySet?.fields?.microcopy?.find(
            (microcopy: IMicrocopy) => microcopy?.fields?.key === microcopyKey
        );

        if (result) {
            const { replacements } = options || {};
            let resultValue = result?.fields?.value;

            if (!isEmpty(replacements)) {
                resultValue = interpolateText(resultValue, replacements);
            }

            return resultValue;
        } else {
            // Remove error logs from unit tests
            // eslint-disable-next-line turbo/no-undeclared-env-vars
            if (!process.env.JEST_WORKER_ID && !options?.fromMultiple) {
                MCLogger(microcopyKey, microcopySetKey, mcType, MicrocopyError.MISSING_MICROCOPY);
            }
            return null;
        }
    };

    const getMultiple = (
        microcopySetKey: MicrocopySetKey,
        microcopyKeys: string[],
        options?: MicrocopyGetOptions
    ): Record<string, string> | null => {
        const result = {};
        const getOptions = options || {};
        getOptions.fromMultiple = true;
        microcopyKeys.forEach((key) => {
            const microcopy = get(microcopySetKey, key, getOptions);

            if (microcopy) {
                result[key] = microcopy;
            }
        });
        return result;
    };

    return {
        get,
        getMultiple,
    };
};

export { MicrocopyContext, MicrocopyProvider, useMicrocopy };
