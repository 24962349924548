const Address = {
    title: "address.title",
    edit: {
        title: "address.edit.title",
    },
    add: {
        title: "address.add.title",
        subheading: "address.add.subheading",
    },
    new: {
        title: "address.new.title",
        button: "address.new.button",
    },
    billing: {
        defaultTitle: "address.billing.defaultTitle",
        useAs: "address.billing.useAs",
    },
    shipping: {
        defaultTitle: "address.shipping.defaultTitle",
        useAs: "address.shipping.useAs",
    },
    defaultBillingShippingAddressTitle: "address.defaultBillingShippingAddressTitle",
    save: "address.save.button",
    form: {
        fullName: "FullNameLabel",
        addressName: "address.form.addressName",
        streetName: "address.form.streetName",
        additionalStreetName: "address.form.additionalStreetName",
        firstName: "address.form.firstName",
        lastName: "address.form.lastName",
        city: "address.form.city",
        zipCode: "address.form.zipCode",
        addAddressLine: "address.form.addAddressLine",
        country: "address.form.country",
        region: "address.form.region",
        placeholderDropdown: "address.form.region.dropdown",
        landmark: "address.form.landmark",
        deliveryInstructions: "address.form.deliveryInstructions",
        addressLookup: "address.form.addressLookup",
        tooltipLabel: "address.form.tooltipLabel",
        tooltipValue: "address.form.tooltipValue",
        noAddressesFound: "address.form.noAddressesFound",
        phoneticFirstName: "address.form.phoneticFirstName",
        phoneticLastName: "address.form.phoneticLastName",
        deliveryDatePicker: "address.form.deliveryDate",
        deliveryTimeSlot: "address.form.deliveryTimeslot",
        noPreference: "address.form.noPreference",
        morning: "address.form.morning",
        from14to16: "address.form.from14to16",
        from16to18: "address.form.from16to18",
        from18to20: "address.form.from18to20",
        from19to21: "address.form.from19to21",
    },
    error: {
        onlyAlphabetic: "address.error.onlyAlphabetic",
        containsNumbers: "address.error.containsNumbers",
        addressField: "address.error.addressField",
        zipCode: "address.error.zipCode.valid",
        countryExists: "address.error.countryExists",
        poBox: "address.error.poBox",
        phoneticInvalid: "address.error.phoneticInvalid",
    },
};

export default Address;
