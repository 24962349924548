import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { useMemo } from "react";
import { CommonCMS } from "@lib/constants/contentful";

const AccessibilityToggle = () => {
    const { getMultiple } = useMicrocopy();

    const microcopies = useMemo(() => {
        const shared = getMultiple(CommonCMS.shared, [
            CommonCMS.accessibilityActivate,
            CommonCMS.accessibilityDeactivate,
        ]);
        return {
            activate: shared[CommonCMS.accessibilityActivate],
            deactivate: shared[CommonCMS.accessibilityDeactivate],
        };
    }, [getMultiple]);

    return (
        <button
            data-ae-cta-opted-in-copy={microcopies.deactivate}
            data-ae-cta-opted-out-copy={microcopies.activate}
            data-ae-cta-optin-button="true"
            id="audioeye_activate_btn"
            type="button"
            style={{
                border: "1px solid #999",
                backgroundColor: "#f0f0f0",
            }}
        >
            {microcopies.activate}
        </button>
    );
};
export default AccessibilityToggle;
