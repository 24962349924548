import { CommonCMS } from "@lib/constants/contentful";
import type {
    MicrocopySetKey,
    MicrocopyGetOptions,
} from "@lib/contentful/microcopy/MicrocopyContext";

export const getAllowedQuestionLabels = (
    getMicrocopy: (
        microcopySetKey: MicrocopySetKey,
        microcopyKey: string,
        options?: MicrocopyGetOptions
    ) => string | null,
    market: string
) => {
    const allowedQuestions = {
        US: [
            "generalInfo",
            "orderStatus",
            "myAccount",
            "productInfo",
            "promo",
            "return",
            "shoeCare",
            "warranty",
            "website",
            "other",
        ],
        CA: [
            "generalInfo",
            "orderStatus",
            "myAccount",
            "productInfo",
            "promo",
            "return",
            "shoeCare",
            "warranty",
            "website",
            "other",
        ],
        IN: [
            "cancelOrder",
            "generalInfo",
            "laceRequest",
            "myAccount",
            "orderStatus",
            "other",
            "productInfo",
            "return",
            "exchange",
            "shoeCare",
            "warranty",
            "website",
            "refunds",
            "promo",
        ],
        AU: [
            "generalInfo",
            "orderStatus",
            "myAccount",
            "productInfo",
            "shoeCare",
            "promo",
            "return",
            "exchange",
            "cancelOrder",
            "shipmentIssues",
            "website",
            "warranty",
            "other",
        ],
        JP: ["orderStatus", "myAccount", "productInfo", "promo", "return", "website", "other"],
    };

    return Object.entries(CommonCMS.contactForm.options)
        .filter(([key]) => allowedQuestions[market]?.includes(key))
        .map(([key, microcopyKey]) => ({
            label: getMicrocopy(CommonCMS.shared, microcopyKey),
            value: key,
        }));
};
